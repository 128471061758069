import {
  Text,
  Badge,
  Flex,
  IconButton,
  Link,
  useClipboard,
} from "@chakra-ui/react";
import { HiOutlinePencil, HiOutlineSquare2Stack } from "react-icons/hi2";
import { formatDate } from "../../../utils/DateformatUtils";

export const marketingSettingColumnsData = (handleModalOpen, link) => [
  {
    Header: "สถานะ",
    accessor: "active",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          colorScheme={cell.value === 1 ? "green" : "red"}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value === 1 ? "ACTIVE" : "INACTIVE"}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ชื่อ-นามสกุล",
    accessor: "fullName",
  },
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
  },
  {
    Header: "รหัสผ่าน",
    accessor: "password",
  },
  {
    Header: "เบอร์โทรศัพท์",
    accessor: "tel",
  },
  {
    Header: "ตำแหน่ง",
    accessor: "role",
  },
  {
    Header: "ลิ้งค์",
    Cell: ({ row }) => {
      const linkText = `${link}&marketing=${row.original.id}`;
      const { onCopy } = useClipboard(linkText);

      return (
        <Flex align="center">
          <IconButton
            icon={<HiOutlineSquare2Stack />}
            onClick={onCopy}
            aria-label="Copy link"
            variant="outline"
            size="sm"
            border="none"
            mr={2}
          />
          <Link href={linkText} color="green.500" isExternal mr={2}>
            <Text isTruncated>{linkText}</Text>
          </Link>
        </Flex>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "Line",
    Cell: ({ row }) => {
      const linkText = row.original.lineId;
      const { onCopy } = useClipboard(linkText);

      return (
        <Flex align="center">
          <IconButton
            icon={<HiOutlineSquare2Stack />}
            onClick={onCopy}
            aria-label="Copy link"
            variant="outline"
            size="sm"
            border="none"
            mr={2}
          />
          <Link href={linkText} color="green.500" isExternal mr={2}>
            <Text isTruncated>{linkText}</Text>
          </Link>
        </Flex>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "วันที่เริ่มทำงาน",
    accessor: "createdDate",
    Cell: ({ value }) => formatDate(value),
  },
  {
    Header: "แก้ไข",
    accessor: "edit",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        borderColor={"green.500"}
        color={"green.500"}
        aria-label="edit"
        size="sm"
        icon={<HiOutlinePencil />}
        onClick={() => handleModalOpen("manageMarketingModal", row.original)}
      >
        แก้ไข
      </IconButton>
    ),
    disableSortBy: true,
  },
];
